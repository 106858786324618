<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
		<div class="section2">
			<div class="box">
				<div class="form-box">
					<div class="item">
						<div class="title"><font color="#ff6000">*</font>公司名称</div>
						<div class="right-box">
							<input class="input" type="text" placeholder="请输入公司名称（必填）" v-model="data.company" />
						</div>
					</div>
					<div class="item">
						<div class="title"><font color="#ff6000">*</font>公司税号</div>
						<div class="right-box">
							<input class="input" type="text" placeholder="请输入公司税号（必填）" v-model="data.shuihao" />
						</div>
					</div>
					<div class="item">
						<div class="title">公司电话</div>
						<div class="right-box">
							<input class="input" type="text" placeholder="请输入公司电话" v-model="data.tel" />
						</div>
					</div>
					<div class="item">
						<div class="title">公司地址</div>
						<div class="right-box">
							<input class="input" type="text" placeholder="请输入公司地址" v-model="data.address" />
						</div>
					</div>
					<div class="item">
						<div class="title">开户银行</div>
						<div class="right-box">
							<input class="input" type="text" placeholder="请输入开户银行" v-model="data.bank" />
						</div>
					</div>
					<div class="item">
						<div class="title">银行账号</div>
						<div class="right-box">
							<input class="input" type="text" placeholder="请输入银行账号" v-model="data.cardid" />
						</div>
					</div>
				</div>
			</div>
			<div class="tip">请填写所要开票公司的开票资料</div>
			<div class="submit-box">
				<div class="submit" @click="submit">{{$route.query.id ? '立即修改' : '立即保存'}}</div>
			</div>
		</div>
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				data: {
					id: 0,
					company: '',
					shuihao: '',
					tel: '',
					address: '',
					bank: '',
					cardid: ''
				}
			}
		},
		computed: {
			uid() {
					return this.$store.state.uid;
			}
		},
		activated(){
			this.getUserInfo();
		},
		deactivated() {
			this.data = {
				id: 0,
				company: '',
				shuihao: '',
				tel: '',
				address: '',
				bank: '',
				cardid: ''
			}
		},
		methods: {
			async formSubmit(data) {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/user/invoiceSave',data);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('开票资料 提交：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.$messageBox.alert('操作成功！', '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.go(-1);
						}
					});
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			submit() {
				var flag = false;
				var	warn = '';
				var data = this.data;
				data['uid'] = this.uid;
				if(!data['uid']){
					warn = '请先登录！';
				} else if(!data['company']){
					warn = '请输入公司名称';
				} else if(!data['shuihao']){
					warn = '请输入公司税号';
				} else {
					flag = true;
					this.formSubmit(data);
				}
				if(flag == false) {
					this.$messageBox.alert(warn, '温馨提示');
					return false;
				}
			},
			async getInfo() {
				var id = this.$route.query.id || 0;
				var uid = this.uid;
				var data = {
					id, uid
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/invoice', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('开票资料 详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var info = res.data;
					this.data = {
						id: info.id,
						company: info.company,
						shuihao: info.shuihao,
						tel: info.tel,
						address: info.address,
						bank: info.bank,
						cardid: info.cardid
					}
				} else {
					this.data = {
						id: 0,
						company: '',
						shuihao: '',
						tel: '',
						address: '',
						bank: '',
						cardid: ''
					}
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return ;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getInfo();
				}
			},
		},
	};
</script>

<style scoped>
	.section2{
		padding-top: 0.3rem;
		padding-bottom: 0.6rem;
	}
	.section2 .box{
		margin-top: 0.3rem;
	}
	.section2 .box:first-child{
		margin-top: 0;
	}
	.section2 .box .form-box{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section2 .box .form-box .item{
		padding: 0 0.3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1rem;
		border-top: 0.02rem solid #e1e1e1;
	}
	.section2 .box .form-box .item:first-child{
		border-top: none;
	}
	.section2 .box .form-box .item .title{
		font-size: 0.28rem;
		color: #333333;
	}
	.section2 .box .form-box .item .right-box{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 5rem;
	}
	.section2 .box .form-box .item .right-box.right-icon{
		background: url(../../../assets/images/icon23.png) no-repeat right center;
		background-size: 0.09rem 0.17rem;
	}
	.section2 .box .form-box .item .right-box.right-icon1{
		background: url(../../../assets/images/icon24.png) no-repeat right center;
		background-size: 0.24rem 0.24rem;
	}
	.section2 .box .form-box .item .right-box .input{
		display: block;
		width: 100%;
		height: 0.6rem;
		font-size: 0.28rem;
		color: #969897;
		text-align: right;
		border: none;
	}
	.section2 .box .form-box .singlepage{
		border-top: 0.02rem solid #e1e1e1;
		padding: 0.4rem 0.3rem;
	}
	.section2 .box .form-box .singlepage .singlepage-title{
		font-size: 0.32rem;
		color: #333333;
		margin-bottom: 0.2rem;
	}
	.section2 .box .form-box .singlepage .singlepage-content{
		font-size: 0.24rem;
		color: #333333;
		line-height: 0.46rem;
	}
	.section2 .tip{
		font-size: 0.28rem;
		color: #ff6000;
		margin: 0.5rem 0.3rem 0;
		padding-left: 0.5rem;
		background: url(../../../assets/images/icon22.png) no-repeat left center;
		background-size: 0.34rem 0.35rem;
		line-height: 0.4rem;
	}
	.section2 .submit-box{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.8rem;
	}
	.section2 .submit-box .submit{
		width: 5.8rem;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #c90719;
		font-size: 0.3rem;
		color: #ffffff;
		border-radius: 0.5rem;
	}
</style>